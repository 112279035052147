import React from 'react';

import * as _ from "lodash";

import Fa from './Fa';

const BtnWrap = props => {

  const {
    activeShrink,
    component,
    children
  } = props;

  const Tag = component || "button";

  return (
    <Tag
      className={"d-inline-block u-cursor-pointer u-bg-none u-outline-0 u-border-0 p-0 m-0"
               + (props.className ? (" " + props.className) : "")
               + (props.activeShrink ? " u-active-shrink-5" : "")}
    >
      {children}
    </Tag>
  );
};

const BtnIcon = props => {
  const {
    className,
    size,
    hoverColor
  } = props;

  let properties = _.cloneDeep(props);

  delete properties.className;
  delete properties.size;

  return (
    <BtnWrap
      className={className}
      activeShrink
    >
      <span
        className={"fw fa-stack fa-sm u-color-transparent u-color-hover-"
                 + (hoverColor ? hoverColor : "primary")
                 + (size ? " fa-" + size : "")}
      >
        <Fa
          className="fa-stack-2x"
          icon="circle"
          weight="fa"
          fw
        />
        <Fa
          className="u-color-primary u-color-hover-white fa-stack-1x"
          {...properties}
          fw
        />
      </span>
    </BtnWrap>
  );
};

export {
  BtnWrap,
  BtnIcon
};
