import React, {
  Component,
  Fragment
} from 'react';

import {
  Fa
} from 'ui_kit';

const UnfurlPreTitle = props => (
  <p className="mb-1 u-opacity-5">
    {props.text}
  </p>
);

const UnfurlTitle = props => (
  <a
    href={props.href}
    target={props.href}
    className="u-text-bold d-inline-block mb-1"
  >
    {props.text}
  </a>
);

const UnfurlDescription = props => (
  <p className="mb-0 u-opacity-7 cozy_mode_hide">
    {props.text}
  </p>
);

const UnfurlLinkImg = props => (
  <a
    href={props.href}
    target={props.href}
    className="Unfurl__LinkPreviewImg d-block u-active-shrink-2"
  >
    <img
      src={props.src}
      alt={props.title}
      style={{
        maxWidth: 120,
        maxHeight: 120
      }}
    />
  </a>
);

const UnfurlFileIcon = props => (
  <div className="Unfurl__FileIcon u-width-3 u-height-3">
    <Fa
      icon={props.icon || "file"}
      weight="far"
    />
  </div>
);

class Unfurl extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      before,
      children
    } = self.props;

    return (
      <div className="Unfurl d-flex">
        {before &&
          <div className="col-auto pl-0 pr-3 cozy_mode_hide">
            {before}
          </div>
        }
        <div className="col p-0">
          {children}
        </div>
      </div>
    )
  }
}

class YouTube extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Unfurl>
        <UnfurlPreTitle
          text="YouTube"
        />
        <UnfurlTitle
          text="Honest Trailers - Spider-Man: Into the Spider-Verse"
          href="https://www.youtube.com/watch?time_continue=8&v=Ye1ir8MQS4w"
        />
        <div className="Unfurl__YouTubeWrap mt-2">
          <div className="content">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Ye1ir8MQS4w"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Unfurl>
    );
  }
}

class Link extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Unfurl
        before={
          <UnfurlLinkImg
            src="https://i.ytimg.com/vi/JyECrGp-Sw8/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&amp;rs=AOn4CLA9-tYszVgFspQNsq7t_AbPz-ut-w"
            href="https://www.youtube.com/watch?v=JyECrGp-Sw8"
            title="What If We Detonated All Nuclear Bombs at Once?"
          />
        }
      >
        <UnfurlPreTitle
          text="Washington Post"
        />
        <UnfurlTitle
          text="What If We Detonated All Nuclear Bombs at Once?"
          href="https://www.youtube.com/watch?v=JyECrGp-Sw8"
        />
        <UnfurlDescription
          text="What happens if we make a huge pile from all 15,000 nuclear bombs and pull the trigger? And what happens if we make an even bigger pile?"
        />
      </Unfurl>
    );
  }
}

class File extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Unfurl
        before={
          <UnfurlFileIcon
            icon="file-pdf"
          />
        }
      >
        <UnfurlPreTitle
          text="PDF"
        />
        <UnfurlTitle
          text="How to Be a Better Person"
          href="https://www.youtube.com/watch?v=JyECrGp-Sw8"
        />
        <UnfurlDescription
          text="No. You're wrong. So, just sit there in your wrongness and be wrong."
        />
      </Unfurl>
    );
  }
}

Unfurl.Link    = Link;
Unfurl.File    = File;
Unfurl.YouTube = YouTube;

export default Unfurl;
