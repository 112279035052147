import React, {
  Component,
  Fragment
} from 'react';

import {
  Avatar,
  Heading
} from 'ui_kit';

import ChatWrap from '../fragments/ChatWrap';
import Conversation from '../fragments/Conversation/Conversation';
import Unfurl from '../fragments/Conversation/Unfurl';

const MessageControls = props => (
  <Fragment>
    <Conversation.MessageControl
      icon="thumbs-up"
      weight="fal"
    />
    <Conversation.MessageControl
      icon="smile-plus"
      weight="fal"
    />
    <Conversation.MessageControl
      icon="pencil"
      weight="fal"
    />
    <Conversation.MessageControl
      icon="trash-alt"
      weight="fal"
      hoverColor="error"
    />
  </Fragment>
);

const ChatHeader = props => (
  <Fragment>
    <Avatar
      src="https://randomuser.me/api/portraits/women/44.jpg"
      presence="busy"
      className="mr-4"
      clean
    />
    <Heading
      tag="h3"
      className="mb-0"
    >
      Amanda Roberts
    </Heading>
  </Fragment>
);

class RoomChat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    return (
      <ChatWrap
        chatHeader={
          <ChatHeader />
        }
      >
        <Conversation.Message
          controls={
            <MessageControls />
          }
        >
          <Conversation.MessageBody/>
        </Conversation.Message>

        <Conversation.Message
          controls={
            <MessageControls />
          }
        >
          <Conversation.MessageBody/>
        </Conversation.Message>

        <Conversation.DayDivider/>

        <Conversation.Message
          controls={
            <MessageControls />
          }
        >
          <Conversation.MessageBody/>
        </Conversation.Message>

        <Conversation.Message
          controls={
            <MessageControls />
          }
        >
          <Conversation.MessageBody/>
          <Unfurl.Link/>
        </Conversation.Message>

        <Conversation.Message
          controls={
            <MessageControls />
          }
        >
          <Conversation.MessageBody/>
          <Unfurl.File/>
        </Conversation.Message>

        <Conversation.Message
          controls={
            <MessageControls />
          }
        >
          <Conversation.MessageBody/>
          <Unfurl.YouTube/>
        </Conversation.Message>

      </ChatWrap>
    );

  }
}

export default RoomChat;
