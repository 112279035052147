import React from 'react';

const Heading = props => {
  let Tag = props.tag ? props.tag : "h1";
  return (
    <Tag
      className={"u-text-medium"
               + " " + (props.inverted ? "u-color-white" : "")
               + " " + (props.className ? props.className : "")
               + " " + (props.color ? "u-color-" + props.color : "")}
    >
      {props.children}
    </Tag>
  );
}

const DetailHeading = props => {
  let Tag = props.tag ? props.tag : "p";
  return (
    <Tag
      className={"u-text-bold small u-text-uppercase"
               + " " + (props.inverted ? "u-color-white" : "")
               + " " + (props.className ? props.className : "")
               + " " + (props.color ? "u-color-" + props.color : "u-color-gray")}
    >
      {props.children}
    </Tag>
  );
}

export {
  Heading,
  DetailHeading
};
