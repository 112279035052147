import React, {
  Component
} from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

class GlobalNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      children,
      style
    } = self.props;

    const GlobalNavStyles = {
      width: 65,
      ...style
    }

    return (
      <div
        className="GlobalNav u-height-p-10"
        style={GlobalNavStyles}
      >
        {children}
      </div>
    );

  }
}

export default GlobalNav;
