import React, {
  Component
} from 'react';

import Dropdown from 'react-bootstrap/Dropdown'

import {
  DetailHeading
} from './Heading';

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    let self = this;

    const {
      children,
      extraClassess
    } = self.props;

    return (
      <div onClick={this.handleClick}
            className={"u-bg-none u-border-0 p-0 m-0 u-outline-0"
                     + (extraClassess ? " " + extraClassess : "")}
      >
        {children}
      </div>
    );
  }
}

class CustomMenu extends React.Component {
  render() {
    let self = this;

    const {
      children,
      style,
      className,
      max
    } = self.props;

    return (
      <div className={className + " u-z-index-10 p-2 u-border-radius-5 u-shadow-3 anim_fade_in_quick"}
                style={style}
                max={max || 10}
                scale={1}
      >
        {children}
      </div>
    );
  }
}

class DropMenu extends Component {
  render() {
    let self = this;

    const {
      children,
      alignRight,
      drop
    } = self.props;

    return (
      <Dropdown
        alignRight={alignRight ? alignRight : null}
        drop={drop || "down"}
      >
        {children}
      </Dropdown>
    );

  }
}

class Menu extends Component {
  render() {
    let self = this;

    const {
      children
    } = self.props;

    return (
      <Dropdown.Menu as={CustomMenu} {...self.props}>
        {children}
      </Dropdown.Menu>
    );

  }
}

class MenuItem extends Component {
  render() {
    let self = this;

    const {
      children,
      active,
      before,
      after
    } = self.props;

    return (
      <Dropdown.Item {...self.props}>
        { before &&
          <div className="col-auto pl-0 pr-2 list-group-item-before">
            {before}
          </div>
        }
        <div className="col p-0">
          {children}
        </div>
        { after &&
          <div className="col-auto pl-2 pr-0">
            {after}
          </div>
        }
      </Dropdown.Item>
    );

  }
}

class MenuItemClean extends Component {
  render() {
    let self = this;

    const {
      children,
      before,
      after
    } = self.props;

    return (
      <div className="dropdown-item clean u-bg-none align-items-center">
        { before &&
          <div className="col-auto pl-0 pr-2">
            {before}
          </div>
        }
        <div className="col p-0">
          {children}
        </div>
        { after &&
          <div className="col-auto pl-2 pr-0">
            {after}
          </div>
        }
      </div>
    );

  }
}

class Toggle extends Component {
  render() {
    let self = this;

    const {
      children,
      className
    } = self.props;

    return (
      <Dropdown.Toggle
        as={CustomToggle}
        extraClassess={className}
      >
        {children}
      </Dropdown.Toggle>
    );

  }
}

class Heading extends Component {
  render() {
    let self = this;

    const {
      children
    } = self.props;

    return (
      <div className="d-block u-width-p-12 p-2">
        <DetailHeading
          className="mb-0 u-cursor-pointer"
        >
          {children}
        </DetailHeading>
      </div>
    );

  }
}

DropMenu.Toggle        = Toggle;
DropMenu.Menu          = Menu;
DropMenu.MenuItem      = MenuItem;
DropMenu.MenuItemClean = MenuItemClean;
DropMenu.ItemHeading   = Heading

export default DropMenu;