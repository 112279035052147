import React, {
  Component,
  Fragment
} from 'react';

class Settings extends Component {
  constructor(){
    super();

    this.state = {}
  }

  render() {
    let self = this;

    return (
      <Fragment>
        SETTINGS
      </Fragment>
    );
  }
}

export default Settings;
