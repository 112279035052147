import React, {
  Component
} from 'react';

import {
  PageWrapper
} from 'ui_kit';

import ChatContainer from './containers/ChatContainer'

import LeftNav  from './fragments/LeftNav'
import ChatList from './fragments/ChatList'

import PersonChat from './pages/PersonChat'
import RoomChat from './pages/RoomChat'

const DATA = {
  "profile": {
    "favorite_group_ids": [
      1, 2, 3, 4, 5
    ]
  },
  "people": [
    {
      "id": 1,
      "name": "Miyah Myles",
      "email": "miyah.myles@gmail.com",
      "position": "Project Manager",
      "photo": "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&s=707b9c33066bf8808c934c8ab394dff6"
    },
    {
      "id": 2,
      "name": "June Cha",
      "email": "june.cha@gmail.com",
      "position": "Customer Service",
      "photo": "https://randomuser.me/api/portraits/women/44.jpg"
    },
    {
      "id": 3,
      "name": "Iida Niskanen",
      "email": "iida.niskanen@gmail.com",
      "position": "Receptionist",
      "photo": "https://randomuser.me/api/portraits/women/68.jpg"
    },
    {
      "id": 4,
      "name": "Renee Sims",
      "email": "renee.sims@gmail.com",
      "position": "Part Time",
      "photo": "https://randomuser.me/api/portraits/women/65.jpg"
    },
    {
      "id": 5,
      "name": "Jonathan Nu\u00f1ez",
      "email": "jonathan.nu\u00f1ez@gmail.com",
      "position": "Accounting",
      "photo": "https://randomuser.me/api/portraits/men/43.jpg"
    },
    {
      "id": 6,
      "name": "Sasha Ho",
      "email": "sasha.ho@gmail.com",
      "position": "Customer Service",
      "photo": "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?h=350&auto=compress&cs=tinysrgb"
    },
    {
      "id": 7,
      "name": "Abdullah Hadley",
      "email": "abdullah.hadley@gmail.com",
      "position": "Software Engineer",
      "photo": "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&s=a72ca28288878f8404a795f39642a46f"
    },
    {
      "id": 8,
      "name": "Veeti Seppanen",
      "email": "veeti.seppanen@gmail.com",
      "position": "Delivery Driver",
      "photo": "https://randomuser.me/api/portraits/men/97.jpg"
    },
    {
      "id": 9,
      "name": "Bonnie Riley",
      "email": "bonnie.riley@gmail.com",
      "position": "Human Resources",
      "photo": "https://randomuser.me/api/portraits/women/26.jpg"
    },
    {
      "id": 10,
      "name": "Thomas Stock",
      "email": "thomas.stock@gmail.com",
      "position": "Receptionist",
      "photo": "https://tinyfac.es/data/avatars/B0298C36-9751-48EF-BE15-80FB9CD11143-500w.jpeg"
    },
    {
      "id": 11,
      "name": "Miyah Myles",
      "email": "miyah.myles@gmail.com",
      "position": "Project Manager",
      "photo": "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&s=707b9c33066bf8808c934c8ab394dff6"
    },
    {
      "id": 12,
      "name": "June Cha",
      "email": "june.cha@gmail.com",
      "position": "Customer Service",
      "photo": "https://randomuser.me/api/portraits/women/44.jpg"
    },
    {
      "id": 13,
      "name": "Iida Niskanen",
      "email": "iida.niskanen@gmail.com",
      "position": "Receptionist",
      "photo": "https://randomuser.me/api/portraits/women/68.jpg"
    },
    {
      "id": 14,
      "name": "Renee Sims",
      "email": "renee.sims@gmail.com",
      "position": "Part Time",
      "photo": "https://randomuser.me/api/portraits/women/65.jpg"
    },
    {
      "id": 15,
      "name": "Jonathan Nu\u00f1ez",
      "email": "jonathan.nu\u00f1ez@gmail.com",
      "position": "Accounting",
      "photo": "https://randomuser.me/api/portraits/men/43.jpg"
    },
  ],
  "presences": [
    {
      "person_id": 1,
      "presence": "online"
    },
    {
      "person_id": 2,
      "presence": "busy"
    },
    {
      "person_id": 3,
      "presence": "busy"
    },
    {
      "person_id": 4,
      "presence": "sleep"
    },
    {
      "person_id": 5,
      "presence": "focus"
    }
  ],
  "teams": [
    {
      "id": 1,
      "name": "Future Leaders",
      "members": [1,2,3],
      "folder": "Coorporate"
    },
    {
      "id": 2,
      "name": "LMS Round Table",
      "members": [4,5,6],
      "folder": "Education BU"
    },
    {
      "id": 3,
      "name": "Happy Hour Hangouts",
      "members": [7,8,9,10],
      "folder": "Education BU"
    },
  ]
};

class Main extends Component {
  constructor(){
    super();
    this.state = {}
  }



  render() {
    let self = this;

    const {
      global_actions,
      view_options
    } = self.props;

    return (
      <ChatContainer
        data={DATA}
      >
        { ({chatData}) => (
          <PageWrapper
            globalNav={
              <LeftNav
                data={chatData}
                onUpdateViewOptions={global_actions.updateViewOption}
                view_options={view_options}
              />
            }
            pageNav={
              <ChatList
                data={chatData}
              />
            }
            data={chatData}
          >
            <RoomChat
              data={chatData}
            />
          </PageWrapper>
        )}
      </ChatContainer>
    );
  }
}

export default Main;
