import React, {
  Component,
  Fragment
} from 'react';

import Container from 'react-bootstrap/Container';

import {
  Page
} from 'ui_kit';

import Composer from '../fragments/Composer';
import Conversation from '../fragments/Conversation/Conversation';

class ChatWrap extends Component {
  constructor(props) {
    super(props);
    this.scrollToConversationBottom = this.scrollToConversationBottom.bind(this);
    this.state = {
      scrollAreaResizeFlag: false
    };
  }

  scrollToConversationBottom() {
    let self = this;
    self.setState((prevState) => ({
      scrollAreaResizeFlag: !prevState.scrollAreaResizeFlag
    }));
  }

  render() {
    let self = this;

    const {
      children,
      chatHeader
    } = self.props;

    const {
      scrollAreaResizeFlag
    } = self.state;

    return (
      <Fragment>
        <Page.Header>
          <div className="d-inline-flex flex-nowrap align-items-center u-height-4">
            {chatHeader}
          </div>
        </Page.Header>
        <Page.Body
          scrollAreaResizeFlag={scrollAreaResizeFlag}
          scrollFromBottom
        >
          <Conversation>
            <Conversation.Thread>
              {children}
            </Conversation.Thread>
          </Conversation>
        </Page.Body>
        <Page.Footer>
          <div className="pt-3 pb-0">
            <Container>
              <Composer
                onHeightChange={self.scrollToConversationBottom}
              />
            </Container>
          </div>
        </Page.Footer>
      </Fragment>
    );

  }
}

export default ChatWrap;
