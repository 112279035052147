import React, {
  Component
} from 'react';

import Container from 'react-bootstrap/Container'
import PerfectScrollbar from 'react-perfect-scrollbar';

const Page = props => (
  <div className="d-flex flex-column u-height-p-10">
    {props.children}
  </div>
);

const PageHeader = props => (
  <div className="col-auto pl-3 pr-3 PageHeader">
    {props.children}
  </div>
);

const PageFooter = props => (
  <div className="col-auto pl-3 pr-3 PageFooter">
    {props.children}
  </div>
);

class PageBody extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    let self = this;

    const {
      scrollFromBottom
    } = self.props;

    if (scrollFromBottom)
    {
      self.setScrollToBottom();
    }
  }

  componentWillReceiveProps(nextProps) {
    let self = this;

    const {
      scrollFromBottom,
      scrollAreaResizeFlag
    } = self.props;

    if (nextProps.scrollAreaResizeFlag !== scrollAreaResizeFlag)
    {
      if (scrollFromBottom)
      {
        self.setScrollToBottom();
      }
    }
  }

  setScrollToBottom() {
    let self = this;
    let scroll_container        = self.scrollRef.current._container;
    let container_scroll_height = scroll_container.scrollHeight;
    let container_height        = scroll_container.offsetHeight;
    self.handleSetScrollPosition(container_scroll_height - container_height);
  }

  handleSetScrollPosition(scrollTop) {
    let self = this;
    self.scrollRef.current._container.scrollTop = scrollTop;
  }

  render() {
    let self = this;

    const {
      children,
      scrollFromBottom
    } = self.props;

    return (
      <div className="col p-0 PageBody u-pos-relative">
        <PerfectScrollbar
          ref={self.scrollRef}
          className="u-pos-absolute u-height-p-10 u-width-p-12 d-flex flex-column"
        >
          <div
            className="d-inline-flex u-width-p-12 pt-5 pb-0"
            style={ scrollFromBottom ? {
              marginTop: "auto"
            } : null}
          >
            <Container>
              {children}
            </Container>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

Page.Header = PageHeader;
Page.Body = PageBody;
Page.Footer = PageFooter;

export default Page;
