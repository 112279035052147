import 'react-perfect-scrollbar/dist/css/styles.css';
import './static/stylesheets/css/style.css';

import React, {
  Component
} from 'react';

import * as _ from "lodash";

import {
  Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import history from './history.js'

import Login from './components/Login'
import Main from './components/Main'
import Settings from './components/Settings'

class App extends Component {
  constructor(){
    super();
    this.updateViewOption = this.updateViewOption.bind(this);
    this.state = {
      view_options: {
        dark_mode: true,
        cozy_mode: false
      }
    }
  }

  updateViewOption(update) {
    let self = this;

    const {
      view_options
    } = self.state;

    self.setState({
      view_options: _.assignIn(_.cloneDeep(view_options), update)
    });
  }

  render() {
    let self = this;

    const {
      view_options
    } = self.state;

    const global_actions = {
      updateViewOption: self.updateViewOption
    }

    return (
      <div
        className={(view_options.dark_mode ? "dark_mode" : "light_mode")
                 + (view_options.cozy_mode ? " cozy_mode" : "")}
      >
        <Router
          history={history}
        >
          <Switch>
            <Route
              path={`/login`}
              render={() =>
                <Login/>
              }
            />
            <Route
              path={`/chat`}
              render={() =>
                <Main
                  global_actions={global_actions}
                  view_options={view_options}
                />
              }
            />
            <Route
              path={`/settings`}
              render={() =>
                <Settings/>
              }
            />
            <Redirect
              from={`/`}
              to={`/chat`}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
