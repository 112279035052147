import React, {
  Component,
  Fragment
} from 'react';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      component,
      before,
      text,
      after,
      nowrap,
      active
    } = self.props;

    const Tag = component || "span";

    return (
      <Tag
        className={"list-group-item" + (active ? " active" : "")}
      >
        {before &&
          <div className="col-auto pl-0 pr-3 d-flex align-items-center list-group-item-before">
            {before}
          </div>
        }
        <div
          className={"col p-0  d-flex align-items-center"
                   + (nowrap ? " u-line-clamp-ellipsis" : "")}
        >
          {text}
        </div>
        {after &&
          <div className="col-auto pl-3 pr-0 d-flex align-items-center list-group-after">
            {after}
          </div>
        }
      </Tag>
    );

  }
}

export default Item;
