import React, {
  Component,
  Fragment
} from 'react';

import Fa from './Fa';

class PresenceIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      presence
    } = self.props;

    let color  = "gray-light";
    let icon   = "";
    let weight = "fa";

    if (presence === "online")
    {
      color  = "confirm";
      icon   = "";
      weight = "";
    }
    else if (presence === "busy")
    {
      color  = "error"
      icon   = "minus";
      weight = "fa";
    }
    else if (presence === "focus")
    {
      color  = "tertiary"
      icon   = "";
      weight = "";
    }
    else if (presence === "offline")
    {
      color  = "gray-light"
      icon   = "";
      weight = "";
    }
    else if (presence === "sleep")
    {
      color  = "primary"
      icon   = "";
      weight = "";
    }

    return (
      <span className="avatar-presence">
        <span className="fa-stack">
          <Fa
            icon="circle"
            weight="fa"
            color={color}
            className="fa-stack-2x"
          />
          { icon &&
            <Fa
              icon={icon}
              weight={weight}
              color="white"
              className="fa-stack-2x"
            />
          }
        </span>
      </span>
    );

  }
}

class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      component,
      src,
      size,
      clean,
      className,
      active,
      presence
    } = self.props;

    const Tag = component || "span";

    return (
      <Tag
        className={"avatar-wrap"
                 + " " + (className ? className : "")
                 + " " + (size ? "avatar-" + size : "")
                 + " " + (clean ? "avatar-clean" : "")
                 + " " + (src ? "avatar-default" : "")
                 + " " + (active ? "active" : "")}
      >
        <span
          className="avatar"
          style={{
            backgroundImage: "url('" + src + "')"
          }}
        >
          { !src &&
            <Fa
              icon="user"
              weight="fa"
            />
          }
        </span>
        { presence &&
          <PresenceIcon presence={presence} />
        }
      </Tag>
    );

  }
}

export {
  PresenceIcon,
  Avatar
};
