import React, {
  Component,
  Fragment
} from 'react';

import Collapsible from 'react-collapsible';

import {
  DetailHeading
} from './Heading';

import Fa from './Fa';

class Group extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.state = {
      open: true
    };
  }

  toggleCollapse() {
    let self = this;

    self.setState((prevState) => ({
      open: !prevState.open
    }))
  }

  render() {
    let self = this;

    const {
      heading,
      className,
      collapsible,
      children
    } = self.props;

    const {
      open
    } = self.state;

    const Title = () => (
      <div
        className="d-flex u-width-p-12 align-items-center justify-content-between mb-2"
      >
        <DetailHeading
          className="mb-0 u-cursor-pointer"
        >
          {heading}
        </DetailHeading>
        {collapsible &&
          <Fa
            icon={"chevron-" + (open ? "down" : "left")}
            weight="fa"
            className="u-color-gray u-opacity-5 small"
          />
        }
      </div>
    );

    return (
      <div
        className={"d-block mb-4 " + (className || "")}
      >
        {(collapsible && heading) ?
          <Collapsible
            trigger={<Title/>}
            open={open}
            transitionTime={150}
            easing="ease-in-out"
            handleTriggerClick={self.toggleCollapse}
          >
            {children}
          </Collapsible>
          :
          <Fragment>
            {heading &&
              <Fragment>
                <Title/>
                {children}
              </Fragment>
            }
          </Fragment>
        }
      </div>
    );
  }
}

export default Group;
