import React from 'react';

const Fa = props => (
  <span
    className={"fa-" + (props.icon ? props.icon : "question")
              + (props.weight ? " " + props.weight : " fal")
              + (props.size ? " fa-" + props.size : "")
              + (props.fw ? " fa-fw" : "")
              + (props.spin ? " fa-spin" : "")
              + (props.color ? " u-color-" + props.color : "")
              + (props.className ? " " + props.className : "" )}
    title={props.title}
    aria-hidden="true"
  ></span>
);

export default Fa;
