import React, {
  Component
} from 'react';

class PageNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      children,
      style
    } = self.props;

    const PageNavStyles = {
      width: 240,
      ...style
    }

    return (
      <div
        className="PageNav u-height-p-10"
        style={PageNavStyles}
      >
        {children}
      </div>
    );

  }
}

export default PageNav;
