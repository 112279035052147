import React, {
  Component
} from 'react';

class Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      children
    } = self.props;

    return (
      <div
        className="Wrapper u-pos-fixed d-flex align-items-stretch u-height-p-10 u-width-p-12"
      >
        {children}
      </div>
    );

  }
}

export default Wrapper;
