import React, {
  Component,
  Fragment
} from 'react';

import {
  Fa
} from 'ui_kit'

import Textarea from 'react-textarea-autosize';

const ComposerBtn = (props) => (
  <div className="ComposerIconAttachment col-auto d-flex align-items-center">
    <span className="fa-stack small u-active-shrink-5">
      <Fa
        icon={props.icon1 || "circle"}
        weight="fa"
        className="fa-stack-2x"
      />
      { props.icon2 &&
        <Fa
          icon={props.icon2}
          weight="fa"
          className="fa-stack-1x"
        />
      }
    </span>
  </div>
);

class Composer extends Component {
  constructor(props) {
    super(props);
    this.handleOnHeightChange = this.handleOnHeightChange.bind(this);
    this.state = {};
  }

  handleOnHeightChange() {
    let self = this;

    const {
      onHeightChange
    } = self.props;

    if (onHeightChange)
    {
      onHeightChange();
    }
  }

  render() {
    let self = this;

    return (
      <div className="ComposerWrap">
        <div className="Composer">
          <ComposerBtn
            icon2="plus"
          />
          <div className="col p-0 d-flex align-items-center">
            <Textarea
              className="form-control"
              onHeightChange={self.handleOnHeightChange}
              autoFocus
              placeholder="Type a message..."
            />
          </div>
          <ComposerBtn
            icon1="smile-beam"
          />
        </div>
        <div className="ComposerComposingText d-flex flex-nowrap align-items-center">
          <Fa
            icon="comment-alt-dots"
            weight="fa"
            className="u-opacity-5 u-color-primary mr-2"
          />
          Amanda Roberts is typing...
        </div>
      </div>
    );

  }
}

export default Composer;
