import React, {
  Component,
  Fragment
} from 'react';

import {
  BtnIcon,
  Avatar,
  PresenceIcon,
  Group,
  Item,
  Fa,
  Heading
} from 'ui_kit'

import PerfectScrollbar from 'react-perfect-scrollbar';

class ChatList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: {}
    };
  }

  componentWillMount() {
    let self = this;
    self.initPeopleGroups();
    self.initRooms();
  }

  initPeopleGroups() {
    let self = this;

    const {
      profile,
      people
    } = self.props.data;

    let groups = {
      favorite: [],
      people: []
    };

    people.forEach(person => {
      if (profile.favorite_group_ids.includes(person.id))
      {
        groups.favorite.push(person);
      }
      else
      {
        groups.people.push(person);
      }
    });

    self.setState({
      groups: groups
    });
  }

  initRooms() {
    let self = this;

    const {
      teams
    } = self.props.data;

    let rooms = {
      rooms: teams
    };

    self.setState({
      rooms: rooms
    });
  }

  render() {
    let self = this;

    const {
      groups,
      rooms
    } = self.state;

    return (
      <div className="ChatList d-flex flex-column u-height-p-10">
        <div className="ChatListHeader col-auto pl-4 pr-4">
          <div className="d-flex u-width-p-12 align-items-center u-height-4">
            <div className="col p-0">
              <Heading
                tag="h6"
                className="mb-0"
              >
                Vector Solutions
              </Heading>
            </div>
            <div className="col-auto pl-1 pr-0">
              <BtnIcon
                icon="cog"
              />
            </div>
          </div>
        </div>
        <div className="col p-0">
          <PerfectScrollbar className="u-height-p-10 pb-4 pt-4 pl-4 pr-4">

            <Group
              heading="Favorites"
              collapsible
            >
              <div
                className="list-group"
              >
                { groups.favorite.map(person =>
                  <Item
                    key={person.id}
                    component="button"
                    text={person.name}
                    before={
                      <Fragment>
                        <span
                          className="cozy_mode_d-inline-block"
                        >
                          <PresenceIcon
                            presence={person.presence}
                          />
                        </span>
                        <span
                          className="cozy_mode_hide"
                        >
                          <Avatar
                            size="medium"
                            presence={person.presence}
                            src={person.photo}
                            clean
                          />
                        </span>
                      </Fragment>
                    }
                    nowrap
                  />
                )}
              </div>
            </Group>

            <Group
              heading="People"
              collapsible
            >
              <div
                className="list-group"
              >
                { groups.people.map(person =>
                  <Item
                    key={person.id}
                    component="button"
                    text={person.name}
                    before={
                      <Fragment>
                        <span
                          className="cozy_mode_d-inline-block"
                        >
                          <PresenceIcon
                            presence={person.presence}
                          />
                        </span>
                        <span
                          className="cozy_mode_hide"
                        >
                          <Avatar
                            size="medium"
                            presence={person.presence}
                            src={person.photo}
                            clean
                          />
                        </span>
                      </Fragment>
                    }
                    nowrap
                  />
                )}
              </div>
            </Group>

            <Group
              heading="Rooms"
              collapsible
            >
              <div
                className="list-group"
              >
                { rooms.rooms.map(room =>
                  <Item
                    key={room.id}
                    component="button"
                    text={room.name}
                    before={
                      <Fragment>
                        <Fa
                          icon="users"
                          size="2x"
                          weight="fal"
                          className="u-color-gray u-opacity-5 cozy_mode_hide"
                        />
                        <Fa
                          icon="users"
                          size="sm"
                          weight="far"
                          className="u-color-gray u-opacity-5 cozy_mode_d-inline"
                        />
                      </Fragment>
                    }
                    nowrap
                  />
                )}
              </div>
            </Group>

          </PerfectScrollbar>
        </div>
      </div>
    );

  }
}

export default ChatList;
