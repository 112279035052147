import React, {
  Component
} from 'react';

import Page from './Page';
import Wrapper from './Wrapper';
import GlobalNav from './GlobalNav';
import PageNav from './PageNav';

class PageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      children,
      globalNav,
      pageNav
    } = self.props;

    return (
      <Wrapper>
        { globalNav &&
          <div className="col-auto p-0">
            <GlobalNav>
              {globalNav}
            </GlobalNav>
          </div>
        }
        { pageNav &&
          <div className="col-auto p-0">
            <PageNav>
              {pageNav}
            </PageNav>
          </div>
        }
        <div className="col p-0">
          <Page>
            {children}
          </Page>
        </div>
      </Wrapper>
    );

  }
}

export default PageWrapper;
