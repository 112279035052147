import React, {
  Component,
  Fragment
} from 'react';

import * as moment from "moment";

import {
  Avatar,
  BtnIcon
} from 'ui_kit';

class MessageControl extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      icon,
      weight,
      hoverColor
    } = self.props;

    return (
      <BtnIcon
        icon={icon}
        weight={weight}
        hoverColor={hoverColor}
      />
    );

  }
}

class MessageBody extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    return (
      <div className="Conversation__Message__Body">
        <p>
          Yo, check this out!&nbsp;
          <a
            href="https://www.youtube.com/watch?v=JyECrGp-Sw8"
            target="https://www.youtube.com/watch?v=JyECrGp-Sw8"
          >
            https://www.youtube.com/watch?v=JyECrGp-Sw8
          </a>
        </p>
      </div>
    );

  }
}

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      children,
      controls
    } = self.props;

    return (
      <div
        className="Conversation__Message u-width-p-12 pr-3 pl-3">
        <div className="col-auto pl-0 pr-3 pt-1">
          <span>
            <Avatar
              src="https://randomuser.me/api/portraits/women/44.jpg"
              size="medium"
              className="cozy_mode_d-inline-block"
              clean
            />
          </span>
          <span>
            <Avatar
              src="https://randomuser.me/api/portraits/women/44.jpg"
              size="large"
              className="cozy_mode_hide"
              clean
            />
          </span>
        </div>
        <div className="col p-0">
          <div className="Conversation__Message__HeaderWrap d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <p className="Conversation__Message__Title small u-text-bold mb-1 mt-1 mr-3">
                Amanda Roberts
              </p>
              { controls &&
                <div className="mt-1 Conversation__Message__Controls small cozy_mode_d-block">
                  {controls}
                </div>
              }
            </div>
            <p className="Conversation__Message__Time small mb-0">
              {moment().format('ddd, MMM D @ LT')}
            </p>
          </div>
          <div className="Conversation__Message__BodyWrap">
            { children &&
              <Fragment>
                {children}
              </Fragment>
            }
          </div>
          { controls &&
            <div className="Conversation__Message__Controls cozy_mode_hide">
              {controls}
            </div>
          }
        </div>
      </div>
    );

  }
}

class DayDivider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    return (
      <div className="Conversation__Thread__DayDivider">
        <div className="col p-0">
          <hr className="m-0"/>
        </div>
        <div className="col-auto pl-3 pr-3">
          <p className="badge m-0">
            {moment().format('ddd, MMM D')}
          </p>
        </div>
        <div className="col p-0">
          <hr className="m-0"/>
        </div>
      </div>
    );

  }
}

class Thread extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      children
    } = self.props;

    return (
      <div className="Conversation__Thread pb-2">
        {children}
      </div>
    );

  }
}

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let self = this;

    const {
      children
    } = self.props;


    return (
      <div className="Conversation">
        {children}
      </div>
    );

  }
}

Conversation.Thread         = Thread;
Conversation.Message        = Message;
Conversation.MessageBody    = MessageBody;
Conversation.MessageControl = MessageControl;
Conversation.DayDivider     = DayDivider;

export default Conversation;
