import React, {
  Component
} from 'react';

class SwitchToggle extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event) {
    var self = this;

    const {
      name,
      onChange
    } = self.props;

    if ( typeof onChange === 'function' )
    {
      onChange({
        [name]: event.target.checked
      });
    }
  }

  render() {
    let self = this;

    const {
      className,
      name,
      value
    } = self.props;

    return (
      <div
        className={"SwitchToggleWrap" + (className ? " " + className : null)}
      >
        <label>
          <input
            className="SwitchToggle"
            type="checkbox"
            name={name}
            name={name}
            id={name}
            checked={value}
            onChange={self.handleOnChange}
          />
          <span className="SwitchToggle__btn"></span>
        </label>
      </div>
    );
  }
}

export default SwitchToggle;