import React, {
  Component
} from 'react';

import {
  Avatar,
  Fa,
  DropMenu,
  SwitchToggle
} from 'ui_kit'

const UserMenu = (props) => (
  <DropMenu
    drop="right"
  >
    <DropMenu.Toggle
      className="d-flex u-width-p-12 justify-content-center u-cursor-pointer"
    >
      <Fa
        icon="cog"
        weight="far"
        size="lg"
        className="LeftNavIcon mb-4"
      />
    </DropMenu.Toggle>
    <DropMenu.Menu>
      <DropMenu.ItemHeading>
        My Stuff
      </DropMenu.ItemHeading>
      <DropMenu.MenuItem
        before={
          <Fa
            icon="cogs"
            className="u-opacity-5"
            fw
          />
        }
      >
        User Settings
      </DropMenu.MenuItem>
      <DropMenu.MenuItem
        before={
          <Fa
            icon="user-circle"
            className="u-opacity-5"
            fw
          />
        }
      >
        My Profile
      </DropMenu.MenuItem>
      <DropMenu.ItemHeading>
        View Options
      </DropMenu.ItemHeading>
      <DropMenu.MenuItemClean
        before={
          <Fa
            icon="moon-stars"
            className="u-opacity-5"
            fw
          />
        }
        after={
          <SwitchToggle
            className="ml-3"
            name="dark_mode"
            value={props.view_options.dark_mode}
            onChange={props.onUpdateViewOption}
          />
        }
      >
        Night Mode
      </DropMenu.MenuItemClean>
      <DropMenu.MenuItemClean
        before={
          <Fa
            icon="list-ul"
            className="u-opacity-5"
            fw
          />
        }
        after={
          <SwitchToggle
            className="ml-3"
            name="cozy_mode"
            value={props.view_options.cozy_mode}
            onChange={props.onUpdateViewOption}
          />
        }
      >
        Cozy
      </DropMenu.MenuItemClean>
    </DropMenu.Menu>
  </DropMenu>
);

class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.handleUpdateViewOption = this.handleUpdateViewOption.bind(this);
    this.state = {};
  }

  handleUpdateViewOption(update) {
    let self = this;

    const {
      onUpdateViewOptions
    } = self.props;

    if ( onUpdateViewOptions )
    {
      onUpdateViewOptions(update);
    }
  }

  render() {
    let self = this;

    const {
      view_options
    } = self.props;

    return (
      <div className="LeftNav d-flex flex-column u-color-white u-height-p-10 pt-4 pb-4">
        <div className="col p-0 d-flex flex-column align-items-center">
          <Fa
            icon="comment-alt-check"
            weight="far"
            size="2x"
            className="LeftNavLogo mb-4"
          />
          <Fa
            icon="search"
            size="lg"
            className="LeftNavIcon mb-4"
          />
          <Fa
            icon="plus"
            size="lg"
            className="LeftNavIcon mb-4"
          />
        </div>
        <div className="col-auto p-0 LeftNavSettingsDropDownMenu">
          <UserMenu
            onUpdateViewOption={self.handleUpdateViewOption}
            view_options={view_options}
          />
        </div>
        <div className="col-auto p-0 d-flex justify-content-center">
          <Avatar
            size="small"
            presence="online"
            clean
            className="u-hover-grow-8"
          />
        </div>
      </div>
    );

  }
}

export default LeftNav;
