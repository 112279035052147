import React, {
  Component,
  Fragment
} from 'react';

import * as _ from "lodash";

class ChatContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  peopleWithPresence() {
    let self = this;

    const {
      presences,
      people
    } = self.props.data;

    let cloned_people = _.cloneDeep(people);

    cloned_people.forEach(person => {
      const presence = _.find(presences, (presence) =>
      {
        return presence.person_id === person.id;
      });
      if (presence)
      {
        person.presence = presence.presence;
      }
      else
      {
        person.presence = "offline";
      }
    });

    return cloned_people;
  }

  render() {
    let self = this;

    const {
      data,
      children
    } = self.props;

    let cloned_data = _.cloneDeep(data);

    cloned_data.people = self.peopleWithPresence();

    return (
      <Fragment>
        {children({
          chatData: cloned_data
        })}
      </Fragment>
    )
  }
}

export default ChatContainer;
